import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";
import { bitCheck } from "../common/components/payment";

import Navbar from "Containers/Navbar";
import InstractionSteps from "../Containers/InstractionSteps";
import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import { useStaticQuery, graphql } from "gatsby";

import Footer from "Containers/Footer";

const PaymentReturn = ({ location }) => {
  const [coupon, setCoupon] = React.useState(null);
  /*  const data = useStaticQuery(graphql`
    query general {
      allGeneralTour {
        nodes {
          tour_id
          available_coupons
        }
      }
    }
  `); */

  //const { thumb, title, features } = data.appCreativeJson.chooseUs;

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    const t_id = params.get("tID");
    const bCheck = params.get("bitCheck");

    const calcBitCheck = bitCheck(t_id);

    if (calcBitCheck === Number(bCheck)) {
      /*    const wanted_tour = data.allGeneralTour.nodes.filter(
        (tour) => tour.id === t_id
      )[0]; */
    }
  }, [location.search]);

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <Seo
          title={`Dguide | Purchase page}`}
          description={"אישור תשלום"}
          lang={"He"}
          meta={[]}
          keywords={[]}
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <InstractionSteps coupon={coupon} />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default PaymentReturn;
